import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import * as builder from '../../ducks/builder';
import TermDialog from '../../../app/components/other/Dialog/TermDialog';
import { LayoutContextConsumer } from '../LayoutContext';
import { injectIntl } from 'react-intl';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      dialogContentType: '',
    };
  }

  handleDialogOpen = contentType => {
    this.setState({
      dialogContentType: contentType,
      openDialog: true,
    });
  };

  handleDialogClose = () => {
    this.setState({
      openDialog: false,
    });
  };
  render() {
    // const today = new Date().getFullYear();
    const { openDialog, dialogContentType } = this.state;
    const { intl, userRole } = this.props;
    const isAdmin = userRole === 'ROLE_ADMIN';
    const isCustomer =
      userRole === 'ROLE_BUYER' ||
      userRole === 'ROLE_BUYER_MANAGER' ||
      userRole === 'ROLE_STORE_MANAGER';
    const isPerformers = userRole === 'ROLE_VENDOR' || userRole === 'ROLE_MANAGER';
    const isWorkers = userRole === 'ROLE_VENDOR_STAFF';
    return (
      <LayoutContextConsumer>
        {({ footer: { show } }) =>
          show && (
            <div
              className={`kt-footer ${this.props.footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`}
              id='kt_footer'
            >
              <div className={`kt-container ${this.props.footerContainerClasses}`}>
                <div className='kt-footer__copyright'>
                  <div>
                    <a
                      href='https://startmobile.us/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='kt-link'
                    >
                      {intl.formatMessage({ id: 'AUTH.CREATOR.COMPANY' })}
                    </a>
                    <br />
                    <a
                      rel='noopener noreferrer'
                      className='kt-link'
                      onClick={() => this.handleDialogOpen('user_agreement')}
                    >
                      {intl.formatMessage({ id: 'SUBMENU.LEGAL_TERMS' })}
                    </a>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <a
                      onClick={() => this.handleDialogOpen('privacy_policy')}
                      rel='noopener noreferrer'
                      className='kt-link'
                    >
                      {intl.formatMessage({ id: 'SUBMENU.LEGAL_PRIVACY' })}
                    </a>
                    {(isAdmin || isCustomer) && (
                      <>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <a
                          onClick={() => this.handleDialogOpen('customer_manual')}
                          rel='noopener noreferrer'
                          className='kt-link'
                        >
                          {intl.formatMessage({ id: 'SUBMENU.CUSTOMER_MANUAL' })}
                        </a>
                      </>
                    )}
                    {(isAdmin || isPerformers) && (
                      <>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <a
                          onClick={() => this.handleDialogOpen('performers_manual')}
                          rel='noopener noreferrer'
                          className='kt-link'
                        >
                          {intl.formatMessage({ id: 'SUBMENU.PERFORMER_MANUAL' })}
                        </a>
                      </>
                    )}
                    {(isAdmin || isWorkers) && (
                      <>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <a
                          onClick={() => this.handleDialogOpen('workers_manual')}
                          rel='noopener noreferrer'
                          className='kt-link'
                        >
                          {intl.formatMessage({ id: 'SUBMENU.WORKER_MANUAL' })}
                        </a>
                      </>
                    )}
                    {isAdmin && (
                      <>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <a
                          onClick={() => this.handleDialogOpen('admins_manual')}
                          rel='noopener noreferrer'
                          className='kt-link'
                        >
                          {intl.formatMessage({ id: 'SUBMENU.ADMIN_MANUAL' })}
                        </a>
                      </>
                    )}
                  </div>
                  {/*<div className='kt-footer__menu'></div>*/}
                  <>
                    <TermDialog
                      isOpen={openDialog}
                      handleClose={this.handleDialogClose}
                      contentType={dialogContentType}
                    />
                  </>
                </div>
              </div>
            </div>
          )
        }
      </LayoutContextConsumer>
    );
  }
}

const mapStateToProps = store => ({
  fluid: objectPath.get(store.builder.layoutConfig, 'footer.self.width') === 'fluid',
  footerClasses: builder.selectors.getClasses(store, {
    path: 'footer',
    toString: true,
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: 'footer_container',
    toString: true,
  }),
});

export default connect(mapStateToProps)(injectIntl(Footer));
