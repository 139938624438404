import { makeStyles, createStyles } from '@material-ui/core';

export const useStylesProducView = makeStyles(theme =>
  createStyles({
    editor: {
      boxShadow: 'initial !important',
      padding: '0 !important',
    },
    container: {
      padding: '0',
      [theme.breakpoints.up('md')]: {
        padding: '0 100px',
      },
    },
    card: {
      padding: '50px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',

      [theme.breakpoints.up('md')]: {
        padding: '50px',
        maxWidth: '1280px',
        margin: 'auto',
      },
    },
    leftCol: {
      maxWidth: '690px',
      [theme.breakpoints.up('md')]: {
        width: '60%',
        marginRight: '20px',
      },
    },
    rightCol: {
      width: '100%',

      [theme.breakpoints.up('md')]: {
        width: '36%',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    name: {
      fontSize: 20,
      fontWeight: 600,
      margin: 0,

      [theme.breakpoints.up('md')]: {
        fontSize: 34,
        lineHeight: 1.2,
      },
    },
    price: {
      color: theme.palette.primary.main,
      fontSize: 20,
      fontWeight: 700,

      [theme.breakpoints.up('md')]: {
        fontSize: 34,
        margin: '15px 0',
      },
    },
    whatsAppButton: {
      width: '100%',
      height: 42,
      padding: '12px 20px',
      borderRadius: 4,
      backgroundColor: '#25d366',
      fontSize: 14,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#fff',
      display: 'inline-block',
      marginTop: 20,

      '&:hover': {
        color: '#fff',
      },

      [theme.breakpoints.up('md')]: {
        width: 215,
      },
    },
    listItem: {
      marginBottom: 15,
    },
    listValue: {
      fontSize: 17,
      fontWeight: 700,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#000000',
      whiteSpace: 'break-spaces',
      margin: 0,
    },
    listValueImportant: {
      fontSize: 17,
      fontWeight: 700,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: theme.palette.primary.main,
      whiteSpace: 'break-spaces',
      margin: 0,
    },
    listKey: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 400,
      color: '#757575',
    },
    companyCard: {
      padding: '20px 25px',
      borderRadius: '4px',
      backgroundColor: 'rgba(54, 160, 247, 0.1)',
      marginTop: 50,
    },
    description: {
      fontSize: 17,
      fontWeight: 200,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: theme.palette.primary.main,
      whiteSpace: 'break-spaces',
      margin: 0,
    },
  })
);
