import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    containerPaper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 20,
      boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    },
    container: {
      paddingTop: 50,
      paddingBottom: 50,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 40,
        paddingRight: 40,
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: 50,
        paddingRight: 50,
      },
    },
    containerTop: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    containerBot: {
      marginTop: 35,
    },
    imgContainer: {
      height: 400,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 280,
        height: 200,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 350,
        height: 250,
      },
    },
    img: {
      maxWidth: 430,
      [theme.breakpoints.down('xs')]: {
        maxWidth: 200,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 250,
      },
      cursor: 'pointer',
    },
    galleryContainer: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: 280,
        maxHeigth: 280,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 350,
        maxHeigth: 350,
      },
    },
    productInfo: {
      paddingLeft: 35,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        marginTop: 25,
      },
    },
    addToCartBtn: {},
    actions: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        marginTop: 10,
      },
    },
    richEditor: {
      [theme.breakpoints.up('lg')]: {
        width: 950,
      },
    },
    richEditor1: {},
    header: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 25,
    },
  })
);

export const useStylesCompanies = makeStyles(theme =>
  createStyles({
    title: {
      marginBottom: 20,
      textTransform: 'capitalize',
    },
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    card: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    addBtn: {},
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    tariffText: {
      margin: 0,
      padding: 0,
      color: '#470D63FF',
      cursor: 'pointer',
    },
    green: {
      color: '#fff',
      backgroundColor: '#566DC6FF',
    },
    companyNameAvatarWrapper: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
  })
);

export const useStylesCompanyEditPage = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(3),
  },
  actions: {
    marginTop: theme.spacing(3),
  },
  buttons: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  btnDel: {
    marginLeft: theme.spacing(2),
  },
  delButton: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  switch: {
    marginLeft: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  managers: {
    minHeight: 55,
    marginTop: 10,
    padding: theme.spacing(1.5),
  },
  managersTitle: {
    marginBottom: 10,
  },
  rootTable: {
    // marginBottom: 20,
    // minHeight: 200,
    // flex: 1,
      margin: '20px 0',
      minHeight: 200,
      flex: 1,
      padding:'0 10px',
      [theme.breakpoints.down("md")]: {
          overflowX:"auto",
          width:"100%"
      },
  },
  table: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'auto',
      overflowY: 'hidden',
      width: '300px',
      whiteSpace: 'nowrap',
      padding: theme.spacing(2),
    },
  },
}));
