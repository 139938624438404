import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';

import * as builder from '../../ducks/builder';
import KTToggle from '../../_assets/js/toggle';
import UserProfile from '../../partials/layout/UserProfile';
import HeaderMobileNavigation from '../../../app/components/ui/HeaderMobileNavigation';
import { UserRoles } from '../../../app/interfaces/user';

class HeaderMobile extends React.Component {
  toggleButtonRef = React.createRef();

  componentDidMount() {
    new KTToggle(this.toggleButtonRef.current, this.props.toggleOptions);
  }

  render() {
    const {
      headerMobileCssClasses,
      headerMobileAttributes,
      headerMenuSelfDisplay,
      me,
    } = this.props;
    return (
      <div
        id='kt_header_mobile'
        className={`kt-header-mobile ${headerMobileCssClasses}`}
        {...headerMobileAttributes}
      >
        <div
          className='kt-header-mobile__toolbar'
          style={{
            flex: 0.4,
            display: me?.roles.includes(UserRoles.ROLE_BUYER) || !me ? 'none' : 'flex',
          }}
        >
          {headerMenuSelfDisplay && (
            <button className='kt-header-mobile__toggler' id='kt_header_mobile_toggler'>
              <span />
            </button>
          )}
        </div>
        <div className='kt-header-mobile__toolbar' style={{ justifyContent: 'flex-end' }}>
          {me?.roles.includes(UserRoles.ROLE_BUYER) ? (
            <HeaderMobileNavigation />
          ) : (
            !me?.roles && <HeaderMobileNavigation />
          )}
          <UserProfile showAvatar={true} showHi={true} showBadge={false} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  me: store.profile.me,
  headerLogo: builder.selectors.getStickyLogo(store),
  asideDisplay: objectPath.get(store.builder.layoutConfig, 'aside.self.display'),
  headerMenuSelfDisplay:
    objectPath.get(store.builder.layoutConfig, 'header.menu.self.display') === true,
  toggleOptions: {
    target: 'body',
    targetState: 'kt-header__topbar--mobile-on',
    togglerState: 'kt-header-mobile__toolbar-topbar-toggler--active',
  },
  headerMobileCssClasses: builder.selectors.getClasses(store, {
    path: 'header_mobile',
    toString: true,
  }),
  headerMobileAttributes: builder.selectors.getAttributes(store, {
    path: 'aside_menu',
  }),
});

export default connect(mapStateToProps)(HeaderMobile);
