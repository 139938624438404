import { TRole } from '../pages/auth/interfaces';
import { menuConfigItems } from '../constants/menuConfigItems';

const {
  profile,
  vendorProducts,
  products,
  // orders,
  users,
  usersBuyer,
  legal,
  // settings,
  catalog,
  // tariffs,
  companies,
  companiesBuyer,
  // vendorUsers,
  vendorCompany,
  catalogForUser,
  chats,
  // tenders,
  buyersTenders,
  vendorsTenders,
  // addTender,
  // allTenders,
  buyersDocuments,
  // buyersOrders,
  usersManager,
  shifts,
  // productsManager,
  shiftsManager,
  statistics,
  shiftsVendor,
  usersVendor,
  shiftsBuyer,
  shiftsStoreManager,
  objectsBuyer,
  shiftsListManager,
  shiftsCalendarManager,
  usersVendorManager,
  objectManager,
  statisticsCustomer,
  objectAdmin,
} = menuConfigItems;

const getDefaultMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      vendorsTenders,
      catalogForUser,
      profile,
      // blog
    ],
  },
});

const getManagerMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      // products,
      // catalogForUser,
      shiftsManager,
      // profile,
      usersManager,
      statisticsCustomer,
      // blog,
      // settings
    ],
  },
});

const getAdminMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      products,
      // orders,
      shifts,
      objectAdmin,
      users,
      companies,
      statistics,
      // tariffs,
      // settings,
      legal,
      // blog,
      // chats
    ],
  },
});

const getVendorMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [shiftsVendor, vendorCompany, usersVendor, statistics],
  },
});

const getBuyerMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [shiftsBuyer, objectsBuyer, companiesBuyer, usersBuyer, statisticsCustomer],
  },
});

const getStoreManager = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [shiftsListManager, shiftsCalendarManager, objectManager, statisticsCustomer],
  },
});

const getVendorManager = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [shiftsManager, usersVendorManager, statistics],
  },
});

const getVendorStaff = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [],
  },
});

const getGuestMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [catalog],
  },
});

const getMenuConfig = (userRoles: TRole[] = ['ROLE_BUYER'], isAuthorized?: boolean) => {
  if (isAuthorized) {
    if (userRoles === undefined) {
      return getGuestMenu();
    }
    if (userRoles[0] === 'ROLE_ADMIN') {
      return getAdminMenu();
    }
    if (userRoles[0] === 'ROLE_MANAGER') {
      return getVendorManager();
    }
    if (userRoles[0] === 'ROLE_VENDOR') {
      return getVendorMenu();
    }
    if (userRoles[0] === 'ROLE_BUYER') {
      return getBuyerMenu();
    }
    if (userRoles[0] === 'ROLE_STORE_MANAGER') {
      return getStoreManager();
    }
    if (userRoles[0] === 'ROLE_BUYER_MANAGER') {
      return getManagerMenu();
    }
    if (userRoles[0] === 'ROLE_VENDOR_STAFF') {
      return getVendorStaff();
    }
  } else {
    return getGuestMenu();
  }
};

export default getMenuConfig;
