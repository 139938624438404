import * as React from 'react';
import { ProductCatalogList } from '../components/Menu';

export const menuConfigItems = {
  profile: {
    root: true,
    title: 'SUBMENU.PROFILE',
    translate: 'SUBMENU.PROFILE',
    page: 'settings/profile',
    activePages: ['settings/profile'],
  },

  logout: {
    root: true,
    title: 'MENU.LOGOUT',
    translate: 'MENU.LOGOUT',
    page: 'logout',
    // icon: 'flaticon-logout',
  },

  products: {
    title: 'SPECIALTIES',
    translate: 'SPECIALTIES',
    bullet: 'dot',
    root: true,
    inActivePages: ['products/types'],
    page: 'categories/tree',
    submenu: [
      // {
      //   title: 'PRODUCT.ALL.PRODUCTS',
      //   translate: 'PRODUCT.ALL.PRODUCTS',
      //   page: 'products/catalog',
      //   activePages: ['products/catalog'],
      //   dynamic: () => <ProductCatalogList />,
      // },
      // {
      //   title: 'PRODUCT.ALL.NEW_PRODUCTS',
      //   page: 'products/new',
      //   translate: 'PRODUCT.ALL.NEW_PRODUCTS',
      // },
      // {
      //   title: 'MENU.COMPANIES.PRODUCTS',
      //   page: 'products/list',
      //   translate: 'MENU.COMPANY.PRODUCTS',
      //   activePages: ['products/edit', 'products/create'],
      // },
      // {
      //   title: 'CATEGORIES.TABLE.TOOLTIP.FAVORITES_PRODUCTS',
      //   page: 'products/favorites',
      //   translate: 'CATEGORIES.TABLE.TOOLTIP.FAVORITES_PRODUCTS',
      // },
      {
        title: 'MENU.CATALOG.CATEGORIES.LIST',
        page: 'categories/list',
        translate: 'MENU.CATALOG.CATEGORIES.LIST',
        activePages: ['categories/edit', 'categories/new'],
      },
      {
        title: 'MENU.CATALOG.CATEGORIES.TREE',
        page: 'categories/tree',
        translate: 'MENU.CATALOG.CATEGORIES.TREE',
      },
      {
        translate: 'SUBMENU.TARIFFS',
        title: 'SUBMENU.TARIFFS',
        page: 'shifts/tariffs',
        activePages: ['shifts/tariffs'],
      },
    ],
  },

  productsManager: {
    title: 'MENU.PRODUCTS',
    translate: 'MENU.PRODUCTS',
    bullet: 'dot',
    root: true,
    activePages: ['products', 'categories', 'viewproduct'],
    inActivePages: ['products/types'],
    page: 'products/catalog',
    submenu: [
      // {
      //   title: 'PRODUCT.ALL.PRODUCTS',
      //   translate: 'PRODUCT.ALL.PRODUCTS',
      //   page: 'products/catalog',
      //   activePages: ['products/catalog'],
      //   dynamic: () => <ProductCatalogList />,
      // },
      // {
      //   title: 'PRODUCT.ALL.NEW_PRODUCTS',
      //   page: 'products/new',
      //   translate: 'PRODUCT.ALL.NEW_PRODUCTS',
      // },
      // {
      //   title: 'MENU.COMPANY.PRODUCTS',
      //   page: 'products/list',
      //   translate: 'MENU.COMPANY.PRODUCTS',
      //   activePages: ['products/edit', 'products/create'],
      // },
      // {
      //   title: 'CATEGORIES.TABLE.TOOLTIP.FAVORITES_PRODUCTS',
      //   page: 'products/favorites',
      //   translate: 'CATEGORIES.TABLE.TOOLTIP.FAVORITES_PRODUCTS',
      // },
      {
        title: 'MENU.CATALOG.CATEGORIES.LIST',
        page: 'categories/list',
        translate: 'MENU.CATALOG.CATEGORIES.LIST',
        activePages: ['categories/edit', 'categories/new'],
      },
      {
        title: 'MENU.CATALOG.CATEGORIES.TREE',
        page: 'categories/tree',
        translate: 'MENU.CATALOG.CATEGORIES.TREE',
      },
    ],
  },
  vendorProducts: {
    title: 'MENU.PRODUCTS',
    translate: 'MENU.PRODUCTS',
    bullet: 'dot',
    root: true,
    activePages: ['products', 'categories', 'viewproduct'],
    submenu: [
      // {
      //   title: 'MENU.CATALOG.PRODUCTS',
      //   translate: 'MENU.CATALOG.PRODUCTS',
      //   page: 'products/catalog',
      //   activePages: ['products/catalog'],
      //   dynamic: () => <ProductCatalogList />,
      // },
      {
        title: 'MENU.COMPANY.PRODUCTS',
        page: 'products/list',
        translate: 'MENU.COMPANY.PRODUCTS',
        activePages: ['products/edit', 'products/create'],
      },
      {
        title: 'CATEGORIES.TABLE.TOOLTIP.FAVORITES_PRODUCTS',
        page: 'products/favorites',
        translate: 'CATEGORIES.TABLE.TOOLTIP.FAVORITES_PRODUCTS',
      },
      {
        title: 'MENU.CATALOG.CATEGORIES.LIST',
        page: 'categories/list',
        translate: 'MENU.CATALOG.CATEGORIES.LIST',
        activePages: ['categories/edit', 'categories/new'],
      },
      {
        title: 'MENU.CATALOG.CATEGORIES.TREE',
        page: 'categories/tree',
        translate: 'MENU.CATALOG.CATEGORIES.TREE',
      },
    ],
  },

  vendorCompany: {
    root: true,
    title: 'MENU.VENDOR.COMPANY',
    translate: 'MENU.VENDOR.COMPANY',
    bullet: 'dot',
    activePages: ['companies', 'stores'],
    page: 'companies/list/vendor',
    // submenu: [
    //   {
    //     translate: 'SUBMENU.COMPANY_SETTINGS',
    //     title: 'SUBMENU.COMPANY_SETTINGS',
    //     page: 'companies/list/vendor',
    //     activePages: ['companies/edit', 'companies/new'],
    //   },
    //   {
    //     translate: 'MENU.CATALOG.STORES',
    //     title: 'MENU.CATALOG.STORES',
    //     page: 'stores/list',
    //     activePages: ['stores/edit', 'stores/create'],
    //   },
    // ],
  },

  tenders: {
    root: true,
    title: 'MENU.TENDERS',
    translate: 'MENU.TENDERS',
    bullet: 'dot',
    activePages: ['tenders/'],
    submenu: [
      {
        translate: 'SUBMENU.ALLTENDERS',
        title: 'SUBMENU.ALLTENDERS',
        page: 'tenders/list/full',
      },
    ],
  },

  allTenders: {
    root: true,
    title: 'MENU.TENDERS',
    translate: 'MENU.TENDERS',
    bullet: 'dot',
    page: 'tenders/list/full',
    activePages: ['tenders', 'tenders/list/full'],
    submenu: [
      {
        translate: 'SUBMENU.ALLTENDERS',
        title: 'SUBMENU.ALLTENDERS',
        page: 'tenders/list/full',
      },
    ],
  },

  buyersTenders: {
    root: true,
    title: 'MENU.TENDERS',
    translate: 'MENU.TENDERS',
    bullet: 'dot',
    page: 'tenders/list/full',
    activePages: ['tenders'],
    submenu: [
      {
        translate: 'SUBMENU.ALLTENDERS',
        title: 'SUBMENU.ALLTENDERS',
        page: 'tenders/list/full',
      },
      {
        translate: 'SUBMENU.MYTENDERS',
        title: 'SUBMENU.MYTENDERS',
        page: 'tenders/list/my',
      },
    ],
  },

  vendorsTenders: {
    root: true,
    title: 'MENU.TENDERS',
    translate: 'MENU.TENDERS',
    bullet: 'dot',
    page: 'tenders/list/full',
    activePages: ['tenders'],
    submenu: [
      {
        translate: 'SUBMENU.TENDER.VENDOR',
        title: 'SUBMENU.TENDER.VENDOR',
        page: 'tenders/list/full',
      },
    ],
  },

  statistics: {
    root: true,
    title: 'STATISTICS',
    translate: 'STATISTICS',
    bullet: 'dot',
    page: 'statistics/staffs',
    activePages: ['statistics'],
    submenu: [
      {
        translate: 'STATISTICS.USERS2',
        title: 'STATISTICS.USERS2',
        page: 'statistics/staffs',
      },
      {
        translate: 'STATISTICS.STORES2',
        title: 'STATISTICS.STORES2',
        page: 'statistics/object',
      },
    ],
  },

  statisticsManager: {
    root: true,
    title: 'STATISTICS',
    translate: 'STATISTICS',
    bullet: 'dot',
    page: 'statistics/staffs',
    activePages: ['statistics'],
    submenu: [
      {
        translate: 'STATISTICS.USERS2',
        title: 'STATISTICS.USERS2',
        page: 'statistics/staffs',
      },
      {
        translate: 'STATISTICS.STORES2',
        title: 'STATISTICS.STORES2',
        page: 'statistics/object',
      },
    ],
  },

  shifts: {
    root: true,
    title: 'MENU.SHIFTS',
    translate: 'MENU.SHIFTS',
    bullet: 'dot',
    page: 'shifts/calendar',
    activePages: ['shifts/calendar','shifts/list','shifts/new'],
    submenu: [
      {
        translate: 'SUBMENU.SHIFTS.CALENDAR',
        title: 'SUBMENU.SHIFTS.CALENDAR',
        page: 'shifts/calendar',
      },
      {
        translate: 'SHIFT.LIST',
        title: 'SHIFT.LIST',
        page: 'shifts/list',
      },
      {
        translate: 'SHIFT.LIST.NEW',
        title: 'SHIFT.LIST.NEW',
        page: 'shifts/new',
      },
      // {
      //   translate: 'SUBMENU.LOCATIOINS',
      //   title: 'SUBMENU.LOCATIOINS',
      //   page: 'shifts/objects',
      // },
    ],
  },
  objectAdmin: {
    root: true,
    title: 'SUBMENU.LOCATIOINS',
    translate: 'SUBMENU.LOCATIOINS',
    bullet: 'dot',
    page: 'shifts/objects',
    activePages: ['shifts/objects'],
  },

  shiftsStoreManager: {
    root: true,
    title: 'MENU.SHIFTS',
    translate: 'MENU.SHIFTS',
    bullet: 'dot',
    page: 'shifts/calendar',
    activePages: ['shifts'],
    submenu: [
      {
        translate: 'SUBMENU.SHIFTS.CALENDAR',
        title: 'SUBMENU.SHIFTS.CALENDAR',
        page: 'shifts/calendar',
      },
      {
        translate: 'SHIFT.LIST',
        title: 'SHIFT.LIST',
        page: 'shifts/list',
      },
    ],
  },

  statisticsCustomer: {
    root: true,
    title: 'STATISTICS',
    translate: 'STATISTICS',
    bullet: 'dot',
    page: 'statistics/object',
  },

  shiftsCalendarManager: {
    root: true,
    title: 'SUBMENU.SHIFTS.CALENDAR',
    translate: 'SUBMENU.SHIFTS.CALENDAR',
    bullet: 'dot',
    page: 'shifts/calendar',
  },

  shiftsListManager: {
    root: true,
    title: 'SHIFT.LIST',
    translate: 'SHIFT.LIST',
    bullet: 'dot',
    page: 'shifts/list',
  },
  objectManager: {
    root: true,
    title: 'SUBMENU.MY.STORE',
    translate: 'SUBMENU.MY.STORE',
    bullet: 'dot',
    page: 'stores/view',
  },

  shiftsBuyer: {
    root: true,
    title: 'MENU.SHIFTS',
    translate: 'MENU.SHIFTS',
    bullet: 'dot',
    page: 'shifts/calendar',
    activePages: ['shifts'],
    submenu: [
      {
        translate: 'SUBMENU.SHIFTS.CALENDAR',
        title: 'SUBMENU.SHIFTS.CALENDAR',
        page: 'shifts/calendar',
      },
      {
        translate: 'SHIFT.LIST',
        title: 'SHIFT.LIST',
        page: 'shifts/list',
      },
    ],
  },

  objectsBuyer: {
    root: true,
    title: 'SUBMENU.LOCATIOINS',
    translate: 'SUBMENU.LOCATIOINS',
    bullet: 'dot',
    page: 'shifts/objects',
    activePages: ['shifts'],
  },

  shiftsVendor: {
    root: true,
    title: 'MENU.SHIFTS',
    translate: 'MENU.SHIFTS',
    bullet: 'dot',
    page: 'shifts/calendar',
    activePages: ['shifts'],
    submenu: [
      {
        translate: 'SUBMENU.SHIFTS.CALENDAR',
        title: 'SUBMENU.SHIFTS.CALENDAR',
        page: 'shifts/calendar',
      },
      {
        translate: 'SHIFT.LIST',
        title: 'SHIFT.LIST',
        page: 'shifts/list',
      },
      {
        translate: 'SHIFT.LIST.NEW',
        title: 'SHIFT.LIST.NEW',
        page: 'shifts/new',
      },
      {
        translate: 'SUBMENU.LOCATIOINS',
        title: 'SUBMENU.LOCATIOINS',
        page: 'shifts/objects',
      },
    ],
  },

  shiftsManager: {
    root: true,
    title: 'MENU.SHIFTS',
    translate: 'MENU.SHIFTS',
    bullet: 'dot',
    page: 'shifts/calendar',
    activePages: ['shifts'],
    submenu: [
      {
        translate: 'SUBMENU.SHIFTS.CALENDAR',
        title: 'SUBMENU.SHIFTS.CALENDAR',
        page: 'shifts/calendar',
      },
      {
        translate: 'SHIFT.LIST',
        title: 'SHIFT.LIST',
        page: 'shifts/list',
      },
      {
        translate: 'SHIFT.LIST.NEW',
        title: 'SHIFT.LIST.NEW',
        page: 'shifts/new',
      },
      {
        translate: 'SUBMENU.LOCATIOINS',
        title: 'SUBMENU.LOCATIOINS',
        page: 'shifts/objects',
      },
    ],
  },

  addTender: {
    root: true,
    title: 'TENDER.BUTTON.ADD',
    translate: 'TENDER.BUTTON.ADD',
    bullet: 'dot',
    page: 'tenders/create',
    submenu: [
      {
        translate: 'MENU.MY_COMPANY',
        title: 'MENU.MY_COMPANY',
        page: 'companies/list',
      },
    ],
  },

  orders: {
    root: true,
    title: 'MENU.ORDERS',
    translate: 'MENU.ORDERS',
    bullet: 'dot',
    activePages: ['orders/'],
    page: 'orders/list/full',
    submenu: [
      {
        translate: 'SUBMENU.ALLORDERS',
        title: 'SUBMENU.ALLORDERS',
        page: 'orders/list/full',
      },
      {
        translate: 'SUBMENU.ALLTENDERS',
        title: 'SUBMENU.ALLTENDERS',
        page: 'tenders/list/full',
      },
      // {
      //   title: 'MENU.DELIVERY.DOCUMENTS',
      //   translate: 'MENU.DELIVERY.DOCUMENTS',
      //   page: 'delivery/list/full',
      // },
      // {
      //   translate: 'SUBMENU.ABANDONEDCHECKOUTS',
      //   title: 'SUBMENU.ABANDONEDCHECKOUTS',
      //   page: 'orders/list/abandoned',
      // },
    ],
  },

  buyersDocuments: {
    root: true,
    title: 'MENU.DELIVERY.DOCUMENTS',
    translate: 'MENU.DELIVERY.DOCUMENTS',
    bullet: 'dot',
    page: 'delivery/list/full',
  },

  buyersDocumentsHistory: {
    root: true,
    title: 'MENU.DELIVERY.DOCUMENTS.HISTORY',
    translate: 'MENU.DELIVERY.DOCUMENTS.HISTORY',
    bullet: 'dot',
    page: 'delivery/list/history',
  },

  buyersOrders: {
    root: true,
    title: 'MENU.MY_ORDERS',
    translate: 'MENU.MY_ORDERS',
    bullet: 'dot',
    page: 'orders/list/full',
    activePages: ['orders'],
  },

  companies: {
    root: true,
    title: 'MENU.COMPANIES',
    translate: 'MENU.COMPANIES',
    bullet: 'dot',
    activePages: ['companies', 'stores', 'viewcompany'],
    page: 'companies/list/vendors',
    submenu: [
      // {
      //   translate: 'MENU.CATALOG.STORES',
      //   title: 'MENU.CATALOG.STORES',
      //   page: 'stores/list',
      // },
      {
        translate: 'MENU.COMPANIES.PERFORMERS',
        title: 'MENU.COMPANIES.PERFORMERS',
        page: 'companies/list/vendors',
      },
      {
        translate: 'MENU.COMPANIES.CUSTOMERS',
        title: 'MENU.COMPANIES.CUSTOMERS',
        page: 'companies/list/customers',
      },
    ],
  },

  companiesBuyer: {
    root: true,
    title: 'MENU.COMPANY',
    translate: 'MENU.COMPANY',
    bullet: 'dot',
    activePages: ['companies', 'stores', 'viewcompany'],
    page: 'companies/list/vendor',
  },

  users: {
    root: true,
    title: 'MENU.USERS',
    translate: 'MENU.USERS',
    bullet: 'dot',
    activePages: ['users'],
    page: 'users/staff',
    submenu: [
      // {
      //   translate: 'SUBMENU.CUSTOMERS',
      //   title: 'SUBMENU.CUSTOMERS',
      //   page: 'users/buyers',
      // },
      {
        translate: 'COMPANY.BUYER.ROLE_BUYERS',
        title: 'COMPANY.BUYER.ROLE_BUYERS',
        page: 'users/buyers',
      },
      {
        translate: 'COMPANY.BUYER.ROLE_BUYER_MANAGERS',
        title: 'COMPANY.BUYER.ROLE_BUYER_MANAGERS',
        page: 'users/buyer-managers',
      },
      {
        translate: 'ROLE_STORE_MANAGERS',
        title: 'ROLE_STORE_MANAGERS',
        page: 'users/stores-managers',
      },
      {
        translate: 'SUBMENU.VENDORS',
        title: 'SUBMENU.VENDORS',
        page: 'users/vendors',
      },
      {
        translate: 'COMPANY.BUYER.ROLE_MANAGERS',
        title: 'COMPANY.BUYER.ROLE_MANAGERS',
        page: 'users/managers',
      },
      {
        translate: 'SUBMENU.VENDORS.STAFF',
        title: 'SUBMENU.VENDORS.STAFF',
        page: 'users/staff',
      },
      // {
      //   translate: 'MENU.COMPANIES.PERFORMERS',
      //   title: 'MENU.COMPANIES.PERFORMERS',
      //   page: 'companies/list',
      // },
    ],
  },

  usersBuyer: {
    root: true,
    title: 'MENU.USERS',
    translate: 'MENU.USERS',
    bullet: 'dot',
    activePages: ['users'],
    page: 'users/buyer-managers',
    submenu: [
      {
        translate: 'REGIONAL.MANAGER.USERS',
        title: 'REGIONAL.MANAGER.USERS',
        page: 'users/buyer-managers',
      },
      {
        translate: 'ROLE_STORE_MANAGERS',
        title: 'ROLE_STORE_MANAGERS',
        page: 'users/stores-managers',
      },
    ],
  },

  usersVendor: {
    root: true,
    title: 'MENU.USERS',
    translate: 'MENU.USERS',
    bullet: 'dot',
    activePages: ['users'],
    page: 'users/staff',
    submenu: [
      // {
      //   translate: 'SUBMENU.CUSTOMERS',
      //   title: 'SUBMENU.CUSTOMERS',
      //   page: 'users/buyers',
      // },
      {
        translate: 'SUBMENU.VENDORS.STAFF',
        title: 'SUBMENU.VENDORS.STAFF',
        page: 'users/staff',
      },
      {
        translate: 'COMPANY.BUYER.ROLE_MANAGERS',
        title: 'COMPANY.BUYER.ROLE_MANAGERS',
        page: 'users/managers',
      },
      // {
      //   translate: 'MENU.COMPANIES.PERFORMERS',
      //   title: 'MENU.COMPANIES.PERFORMERS',
      //   page: 'companies/list',
      // },
    ],
  },

  usersManager: {
    root: true,
    // title: 'MENU.USERS',
    title: 'ROLE_STORE_MANAGERS',
    // translate: 'MENU.USERS',
    translate: 'ROLE_STORE_MANAGERS',
    bullet: 'dot',
    activePages: ['users'],
    page: 'users/stores-managers',
    // submenu: [
    // {
    //   translate: 'SUBMENU.VENDORS',
    //   title: 'SUBMENU.VENDORS',
    //   page: 'users/vendors',
    // },
    // {
    //   translate: 'SUBMENU.VENDORS.STAFF',
    //   title: 'SUBMENU.VENDORS.STAFF',
    //   page: 'users/staff',
    // },
    // {
    //   translate: 'SUBMENU.COMPANIES',
    //   title: 'SUBMENU.COMPANIES',
    //   page: 'companies/list',
    // },
    // ],
  },

  usersVendorManager: {
    root: true,
    title: 'SUBMENU.VENDORS.STAFF',
    translate: 'SUBMENU.VENDORS.STAFF',
    bullet: 'dot',
    activePages: ['users'],
    page: 'users/staff',
  },

  vendorUsers: {
    root: true,
    title: 'MENU.TEAM',
    translate: 'MENU.TEAM',
    bullet: 'dot',
    page: 'users/managers',
    activePages: ['users'],
  },

  settings: {
    root: true,
    title: 'MENU.SETTINGS',
    bullet: 'dot',
    translate: 'MENU.SETTINGS',
    activePages: ['products/types'],
    page: 'products/types/list',
    submenu: [
      {
        title: 'MENU.PRODUCTS.TYPES',
        translate: 'MENU.PRODUCTS.TYPES',
        page: 'products/types/list',
        activePages: ['products/types/create'],
      },
      // {
      //   title: 'COLLECTIONS',
      //   translate: 'COLLECTIONS',
      //   page: 'collections/list',
      //   activePages: ['collections/list'],
      // },
      // {
      //   title: 'MENU.PRODUCTS.PROMOCODES',
      //   translate: 'MENU.PRODUCTS.PROMOCODES',
      //   page: 'promocodes/list',
      //   activePages: ['promocodes', 'promocodes/edit', 'promocodes/new'],
      // },
      // {
      //   title: 'MENU.PRODUCTS.REFFERALS',
      //   translate: 'MENU.PRODUCTS.REFFERALS',
      //   page: 'refferals/list',
      //   activePages: ['refferals', 'refferals/edit', 'refferals/new'],
      // },
      // {
      //   title: 'MENU.PRODUCTS.TRANSACTIONS',
      //   translate: 'MENU.PRODUCTS.TRANSACTIONS',
      //   page: 'transactions/list',
      //   activePages: ['transactions'],
      // },
    ],
  },

  legal: {
    root: true,
    title: 'SUBMENU.LEGAL',
    translate: 'SUBMENU.LEGAL',
    bullet: 'dot',
    activePages: ['documents'],
    page: 'documents/legacy',
    submenu: [
      {
        title: 'SUBMENU.LEGAL_TERMS',
        translate: 'SUBMENU.LEGAL_TERMS',
        page: 'documents/legacy',
      },
      {
        title: 'SUBMENU.LEGAL_PRIVACY',
        translate: 'SUBMENU.LEGAL_PRIVACY',
        page: 'documents/privacy',
      },
      {
        title: 'SUBMENU.CUSTOMER_MANUAL',
        translate: 'SUBMENU.CUSTOMER_MANUAL',
        page: 'documents/customer',
      },
      {
        title: 'SUBMENU.PERFORMER_MANUAL',
        translate: 'SUBMENU.PERFORMER_MANUAL',
        page: 'documents/performers',
      },
      {
        title: 'SUBMENU.WORKER_MANUAL',
        translate: 'SUBMENU.WORKER_MANUAL',
        page: 'documents/workers',
      },
      {
        title: 'SUBMENU.ADMIN_MANUAL',
        translate: 'SUBMENU.ADMIN_MANUAL',
        page: 'documents/admins',
      },
      // {
      //   title: 'SUBMENU.LEGAL_SHIPPING',
      //   translate: 'SUBMENU.LEGAL_SHIPPING',
      //   page: 'documents/shipping',
      // },
      // {
      //   title: 'SUBMENU.LEGAL_REFUND',
      //   translate: 'SUBMENU.LEGAL_REFUND',
      //   page: 'documents/refund',
      // },
    ],
  },

  catalog: {
    root: true,
    title: 'MENU.CATALOG.PRODUCTS',
    translate: 'MENU.CATALOG.PRODUCTS',
    bullet: 'dot',
    // icon: 'flaticon-book',
    page: 'products/catalog',
    dynamic: () => <ProductCatalogList />,
    activePages: ['products', 'viewproduct'],
    submenu: [
      {
        title: 'MENU.CATALOG.PRODUCTS',
        translate: 'MENU.CATALOG.PRODUCTS',
        page: 'products/catalog',
        activePages: ['products/catalog'],
        dynamic: () => <ProductCatalogList />,
      },
      {
        title: 'COLLECTIONS',
        translate: 'COLLECTIONS',
        page: 'collections/list',
        activePages: ['collections/list'],
      },
    ],
  },

  catalogForUser: {
    root: true,
    title: 'MENU.CATALOG.PRODUCTS',
    translate: 'MENU.CATALOG.PRODUCTS',
    bullet: 'dot',
    // icon: 'flaticon-book',
    page: 'products/catalog',
    dynamic: () => <ProductCatalogList />,
    activePages: ['products', 'viewproduct'],
    submenu: [
      {
        title: 'MENU.CATALOG.PRODUCTS',
        translate: 'MENU.CATALOG.PRODUCTS',
        page: 'products/catalog',
        activePages: ['products/catalog'],
        dynamic: () => <ProductCatalogList />,
      },
      {
        title: 'CATEGORIES.TABLE.TOOLTIP.FAVORITES_PRODUCTS',
        page: 'products/favorites',
        translate: 'CATEGORIES.TABLE.TOOLTIP.FAVORITES_PRODUCTS',
      },
      {
        title: 'COLLECTIONS',
        translate: 'COLLECTIONS',
        page: 'collections/list',
        activePages: ['collections/list'],
      },
    ],
  },

  login: {
    root: true,
    title: 'MENU.LOGIN',
    translate: 'MENU.LOGIN',
    bullet: 'dot',
    // icon: 'flaticon-login',
    page: 'auth',
  },

  tariffs: {
    root: true,
    title: 'SUBMENU.TARIFFS',
    translate: 'SUBMENU.TARIFFS',
    bullet: 'dot',
    activePages: ['tariffs'],
    page: 'tariffs/list/vendors',
    submenu: [
      {
        title: 'SUBMENU.TARIFFS.BUYERS',
        page: 'tariffs/list/buyers',
        translate: 'SUBMENU.TARIFFS.BUYERS',
      },
      {
        title: 'SUBMENU.TARIFFS.VENDORS',
        page: 'tariffs/list/vendors',
        translate: 'SUBMENU.TARIFFS.VENDORS',
      },
    ],
  },

  buyersTariffs: {
    root: true,
    title: 'SUBMENU.TARIFFS',
    translate: 'SUBMENU.TARIFFS',
    bullet: 'dot',
    page: 'tariffs/list/buyers',
    activePages: ['tariffs'],
  },

  vendorsTariffs: {
    root: true,
    title: 'SUBMENU.TARIFFS',
    translate: 'SUBMENU.TARIFFS',
    bullet: 'dot',
    page: 'tariffs/list/vendors',
    activePages: ['tariffs'],
  },

  chats: {
    root: true,
    title: 'SUBMENU.CHATS',
    translate: 'SUBMENU.CHATS',
    bullet: 'dot',
    page: 'chats',
    activePages: ['chats'],
  },
};
