import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { CheckInSystem } from './CheckInSystem';
import { EnterPassword } from './EnterPassword';
import { EnterConfirmCode } from './EnterConfirmCode';
import { ForgotPassword } from './ForgotPassword';
import { Registration } from './Registration';
import { EmailSent } from './EmailSent';

export type TAuthTabs =
  | 'CheckInSystem'
  | 'EnterPassword'
  | 'ForgotPassword'
  | 'EnterConfirmCode'
  | 'Registration'
  | 'EmailSent';

export const AuthTabs: React.FC = () => {
  const params = useLocation<any>();

  const [tab, setTab] = useState<TAuthTabs>('CheckInSystem');
  const [tabProps, setTabProps] = useState<any>({});

  useEffect(() => {
    if (params.state?.me) {
      setTab('EnterPassword');
      setTabProps({ me: params.state.me });
    }
  }, []);

  const authTabSwitcher = useCallback(() => {
    switch (tab) {
      case 'CheckInSystem':
        return <CheckInSystem changeAuthTab={changeAuthTab} {...tabProps} />;
      case 'EnterPassword':
        return <EnterPassword changeAuthTab={changeAuthTab} {...tabProps} />;
      case 'EnterConfirmCode':
        return <EnterConfirmCode changeAuthTab={changeAuthTab} {...tabProps} />;
      case 'ForgotPassword':
        return <ForgotPassword changeAuthTab={changeAuthTab} {...tabProps} />;
      case 'Registration':
        return <Registration changeAuthTab={changeAuthTab} {...tabProps} />;
      case 'EmailSent':
        return <EmailSent changeAuthTab={changeAuthTab} {...tabProps} />;
      default:
        return <></>;
    }
  }, [tab]);

  const changeAuthTab = useCallback((newTab: TAuthTabs, props: any = {}) => {
    setTab(newTab);
    setTabProps(props);
  }, []);

  return authTabSwitcher();
};
