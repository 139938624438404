import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { makeAxiosPostRequest } from '../../../utils/utils';

export const useAxiosRegistrations = () => {
  const intl = useIntl();

  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const [requestedType, setRequestedType] = useState<'email' | 'phone' | null>(null);

  const makeRequest = useCallback(
    (type: 'email' | 'phone', submitValues: any, countryData: any) => {
      const commonData = {
        roles: submitValues.role ? [submitValues.role] : undefined,
        fio: submitValues.fio,
        locale: intl.locale,
        countryId: countryData.id,
        countryCode: countryData.code,
      };

      let url = '/api/_p/user/register';
      let data = null;

      if (type === 'email') {
        data = {
          ...commonData,
          email: submitValues.email,
          login: submitValues.email,
        };
      }

      if (type === 'phone') {
        url = '/api/users/code_register_sms';
        data = {
          ...commonData,
          phoneNumbers: submitValues.phone,
          login: submitValues.phone,
        };
      }

      setRequestedType(type);
      makeAxiosPostRequest(url, data, setData, setLoading, setErr);
    },
    [intl]
  );

  return [data, loading, err, makeRequest, requestedType];
};
