import { IntlShape } from 'react-intl';

import { IUser, UserRoles, UserRoleType } from '../../../../../interfaces/user';
import {
  UserListType,
  BUYER_ROLE,
  MANAGER_ROLE,
  VENDOR_ROLE,
  VENDOR_STAFF_ROLE,
  ROLE_STORE_MANAGER,
  ROLE_BUYER_MANAGER,
} from '../../constants';

export const getRoleName = (roles: [UserRoleType], intl?: IntlShape): string => {
  if (roles.includes(UserRoles.ROLE_ADMIN)) {
    return intl ? intl.formatMessage({ id: 'ADMIN.COMMENT' }) : UserRoles.ROLE_ADMIN;
  }
  if (roles.includes(UserRoles.ROLE_MANAGER)) {
    return intl ? intl.formatMessage({ id: 'ROLE_MANAGER.COMMENT' }) : UserRoles.ROLE_MANAGER;
  }
  if (roles.includes(UserRoles.ROLE_VENDOR)) {
    return intl ? intl.formatMessage({ id: 'SUBMENU.VENDORS' }) : UserRoles.ROLE_VENDOR;
  }
  if (roles.includes(UserRoles.ROLE_BUYER)) {
    return intl ? intl.formatMessage({ id: 'COMPANY.BUYER.ROLE_BUYER' }) : UserRoles.ROLE_BUYER;
  }
  if (roles.includes(UserRoles.ROLE_BUYER_MANAGER)) {
    return intl ? intl.formatMessage({ id: 'COMPANY.BUYER.ROLE_BUYER_MANAGER' }) : UserRoles.ROLE_BUYER_MANAGER;
  }
  if (roles.includes(UserRoles.ROLE_STORE_MANAGER)) {
    return intl ? intl.formatMessage({ id: 'ROLE_STORE_MANAGER' }) : UserRoles.ROLE_STORE_MANAGER;
  }
  if (roles.includes(UserRoles.ROLE_VENDOR_STAFF)) {
    return intl
      ? intl.formatMessage({ id: 'USER.ROLES.VENDOR.STAFF' })
      : UserRoles.ROLE_VENDOR_STAFF;
  }
  return intl ? intl.formatMessage({ id: 'USER.ROLES.VENDOR.STAFF' }) : UserRoles.ROLE_VENDOR_STAFF;
};

export const getRoleForUserPage = (role: UserRoleType) => {
  switch (role) {
    case BUYER_ROLE:
      return UserListType.BUYER;
    case MANAGER_ROLE:
      return UserListType.MANAGER;
    case VENDOR_ROLE:
      return UserListType.VENDOR;
    case VENDOR_STAFF_ROLE:
      return UserListType.STAFF;
    case ROLE_STORE_MANAGER:
      return UserListType.STORE_MANAGER;
    case ROLE_BUYER_MANAGER:
      return UserListType.ROLE_BUYER_MANAGER;
    default:
      return UserListType.BUYER;
  }
};

export const getRole = (type: string) => {
  switch (type) {
    case UserListType.BUYER:
      return BUYER_ROLE;
    case UserListType.MANAGER:
      return MANAGER_ROLE;
    case UserListType.VENDOR:
      return VENDOR_ROLE;
    case UserListType.STAFF:
      return VENDOR_STAFF_ROLE;
    case UserListType.STORE_MANAGER:
      return ROLE_STORE_MANAGER;
    case UserListType.ROLE_BUYER_MANAGER:
      return ROLE_BUYER_MANAGER;
    default:
      return BUYER_ROLE;
  }
};

export const getRoleBreadcrumbName = (type: string, meBuyer?: boolean | null) => {
  switch (type) {
    case UserListType.BUYER:
      return 'COMPANY.BUYER.ROLE_BUYERS';
    case UserListType.MANAGER:
      return 'COMPANY.BUYER.ROLE_MANAGERS';
    case UserListType.VENDOR:
      return 'SUBMENU.VENDORS';
    case UserListType.STAFF:
      return 'SUBMENU.VENDORS.STAFF';
    case UserListType.STORE_MANAGER:
      return 'ROLE_STORE_MANAGERS';
    case UserListType.ROLE_BUYER_MANAGER:
      return !meBuyer ? 'COMPANY.BUYER.ROLE_BUYER_MANAGERS' : 'REGIONAL.MANAGER.USERS';
    default:
      return 'SUBMENU.CUSTOMERS.LIST';
  }
};

export const getFio = (user: IUser, returnEmail?: boolean) => {
  let ret = '';
  if (user.last_name) {
    ret += `  ${user.last_name}`;
  }
  if (user.first_name) {
    ret += `  ${user.first_name}`;
  }
  // if (user.middle_name) {
  //   ret += `  ${user.middle_name}`;
  // }
  if (ret === '') {
    if (returnEmail) {
      return user.email ? `  ${user.email}` : '';
    }
    return '-';
  }
  return ret;
};
export const getFioSearch = (user: IUser, returnEmail?: boolean) => {
  return user?.id ? getFio(user) : '';
  let ret = '';
  if (user.last_name) {
    ret += `${user.last_name}`;
  }
  // if (user.first_name) {
  //   ret += ` ${user.first_name}`;
  // }
  return ret;
};

export const isCustomer = (
  role:
    | 'ROLE_BUYER'
    | 'ROLE_MANAGER'
    | 'ROLE_VENDOR'
    | 'ROLE_VENDOR_STAFF'
    | 'ROLE_STORE_MANAGER'
    | 'ROLE_BUYER_MANAGER'
    | string
    | undefined
) => role === 'ROLE_BUYER' || role === 'ROLE_BUYER_MANAGER' || role === 'ROLE_STORE_MANAGER';
