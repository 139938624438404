export enum UserListType {
  BUYER = 'buyers',
  MANAGER = 'managers',
  VENDOR = 'vendors',
  STAFF = 'staff',
  STORE_MANAGER = 'stores-managers',
  ROLE_BUYER_MANAGER = 'buyer-managers',
}

export const ADMIN_ROLE = 'ROLE_ADMIN';
export const BUYER_ROLE = 'ROLE_BUYER';
export const MANAGER_ROLE = 'ROLE_MANAGER';
export const VENDOR_ROLE = 'ROLE_VENDOR';
export const VENDOR_STAFF_ROLE = 'ROLE_VENDOR_STAFF';
export const ROLE_STORE_MANAGER = 'ROLE_STORE_MANAGER';
export const ROLE_BUYER_MANAGER = 'ROLE_BUYER_MANAGER';
export const ALL_ROLE =
  'ROLE_ADMIN,ROLE_MANAGER,ROLE_VENDOR,ROLE_USER,ROLE_VENDOR_STAFF,ROLE_STORE_MANAGER,ROLE_BUYER_MANAGER';
