import axios from 'axios';
import {
  createReviewUrl,
  delPhotoFromProduct,
  delReviewUrl,
  FOR_DAYS_URL,
  getProductByIdUrl,
  getReviewsUrl,
  PRODUCT_URL,
  PRODUCTS_URL,
  setProductMainPhotoUrl,
} from '../constants';
import { IProductFilter, IReviewCreate } from '../interfaces/product';

export const createProduct = (files: FormData) =>
  axios.post(PRODUCT_URL, files, {
    headers: { 'content-type': 'multipart/form-data' },
  });

export const editProduct = (id: number, files: FormData) =>
  axios.post(getProductByIdUrl(id), files, {
    headers: { 'content-type': 'multipart/form-data' },
  });

export const deleteProduct = (id: number | undefined) =>
  axios.delete(getProductByIdUrl(id || -1));

export const getProductById = (id: number) => axios.get(getProductByIdUrl(id));

export const getProducts = ({
  page = 1,
  perPage = 20,
  companyId,
  categoryId,
  search,
  filter = {},
}: {
  page: number;
  perPage: number;
  companyId?: number;
  categoryId?: number;
  search?: string;
  filter?: Partial<IProductFilter>;
}) => {
  if (search) {
    let url = `/api/shop/products/all/?page=${page}&per_page=${perPage}`;
    if (search) url += `&text=${search}`;
    return axios.get(url);
  }
  if (companyId) {
    let url = `/api/shop/company/${companyId}/products?page=${page}&per_page=${perPage}`;
    if (categoryId) url += `&category_id=${categoryId}`;
    return axios.get(url);
  }
  if (categoryId) {
    if (!filter.parameters) {
      return axios.post(
        `/api/shop/category/${categoryId}/products?page=${page}&per_page=${perPage}`
      );
    }
    const formData = new FormData();
    formData.append('parameters', JSON.stringify(filter.parameters));
    return axios.post(
      `/api/shop/category/${categoryId}/products?page=${page}&per_page=${perPage}&price_from=${filter.price_from}&price_to=${filter.price_to}`,
      formData
    );
  }

  if (filter.parameters) {
    const formData = new FormData();
    formData.append('parameters', JSON.stringify(filter.parameters));
    return axios.post(
      `/api/shop/category/${
        categoryId || 0
      }/products?page=${page}&per_page=${perPage}&price_from=${filter.price_from}&price_to=${
        filter.price_to
      }`,
      formData
    );
  }

  const body = {
    price_from: filter.price_from ? +filter.price_from : null,
    price_to: filter.price_to ? +filter.price_to : null,
  };

  return axios.post(PRODUCTS_URL, body, {
    params: {
      page,
      per_page: perPage,
    },
  });
};

export const delPhoto = (photoId: number) => axios.delete(delPhotoFromProduct(photoId));

export function setMainPhoto(id: number) {
  return axios.post(setProductMainPhotoUrl(id));
}

export const getReviews = (productId: number, perPage: number, page: number) =>
  axios.get(getReviewsUrl(productId, perPage, page));

export const createReview = (productId: number, body: IReviewCreate) =>
  axios.post(createReviewUrl(productId), body);

export const delReview = (reviewId: number) => axios.delete(delReviewUrl(reviewId));

export const getMyFavorites = () => axios.get('/api/shop/favorites');

export const addMyFavorites = (product_id: number) =>
  axios.post('/api/shop/favorites', {}, { params: { product_id } });

export const deleteMyFavorites = (product_id: number) =>
  axios.delete('/api/shop/favorites', { params: { product_id } });

export const changeMyFavorites = (product_id: number, deleteFav: boolean) =>
  deleteFav ? deleteMyFavorites(product_id) : addMyFavorites(product_id);

export const getProductsForDays = ({
  page = 1,
  perPage = 20,
  forDays = 0,
}: {
  page: number;
  perPage: number;
  forDays: number;
}) =>
  axios.post(
    PRODUCTS_URL,
    {
      for_days: forDays,
    },
    {
      params: {
        page,
        per_page: perPage,
      },
    }
  );

export const getForDays = () => axios.get(FOR_DAYS_URL);
export const addForDays = (days: number) => axios.post(`${FOR_DAYS_URL}?days=${days}`);

export const editStock = (product_id: number, body: FormData) =>
  axios.post(`/api/stock_info/${product_id}`, body);
