import React, { useMemo, useState } from 'react';
import { Card, IconButton, makeStyles } from '@material-ui/core';
import ImageGalleryLib from 'react-image-gallery';
import ArrowBack from '@material-ui/icons/ArrowBackIos';
import ArrowNext from '@material-ui/icons/ArrowForwardIos';

import { IProduct } from '../../../../interfaces/product';
import { API_DOMAIN } from '../../../../constants';
import { toAbsoluteUrl } from '../../../../../_metronic';

interface IProps {
  product: IProduct;
}

const useStyles = makeStyles(theme => ({
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0,
    backgroundColor: 'transparent',
  },
  imgContainerSmall: {
    maxHeight: '250px',
    borderRadius: 0,
    [theme.breakpoints.up('md')]: {
      maxHeight: '85vh',
      borderRadius: 0,
    },
  },
  imgContainerFull: {
    maxHeight: '90vh',
    borderRadius: 0,
  },
  img: {
    width: '100%',
    // height: '100%',
    objectFit: 'contain',
    objectPosition: 'center center',
    backgroundColor: 'transparent',
  },
  galleryContainer: {
    width: '100%',
    marginBottom: '50px'
  },
  arrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
    color: '#470D63FF',
  },
}));

const ImageGallery: React.FC<IProps> = ({ product }) => {
  const classes = useStyles();
  const [isFullScreen, setIsFullScreen] = useState(false);

  // images
  const images = useMemo(() => {
    if (product?.photos) {
      return product.photos
        .sort(a => (a.main ? -1 : 0))
        .map(el => ({
          original: el.big ? (API_DOMAIN + el.big) : toAbsoluteUrl('/images/placeholder.png'),
          thumbnail: el.small ? (API_DOMAIN + el.small) : toAbsoluteUrl('/images/placeholder.png'),
          renderItem: ({ original }: any) => (
            <Card
              className={`${classes.imgContainer} ${isFullScreen ? classes.imgContainerFull : classes.imgContainerSmall}`}
              style={{ height: '100%' }}
              onError={(e: any) => {
                e.target.src = toAbsoluteUrl('/images/placeholder.png');
              }}
            >
              <img
                alt=''
                className={classes.img}
                style={{ objectFit: 'contain' }}
                src={original}
              />
            </Card>
          ),
        }));
    }

    return null;
  }, [product, isFullScreen]);

  return (
    <div className={classes.galleryContainer}>
      {images && (
        <>
          {images.length > 0 ? (
            <ImageGalleryLib
              onScreenChange={boolean => setIsFullScreen(boolean)}
              items={images}
              useBrowserFullscreen
              showThumbnails={images.length > 1}
              showFullscreenButton
              disableThumbnailScroll={false}
              showPlayButton={false}
              thumbnailPosition='left'
              infinite
              renderLeftNav={onClick => (
                <IconButton onClick={onClick} className={classes.arrow} style={{ left: 0 }}>
                  <ArrowBack style={{ fontSize: 30 }} />
                </IconButton>
              )}
              renderRightNav={onClick => (
                <IconButton onClick={onClick} className={classes.arrow} style={{ right: 0 }}>
                  <ArrowNext style={{ fontSize: 30 }} />
                </IconButton>
              )}
              onThumbnailError={(e: any) => {
                e.target.src = toAbsoluteUrl('/images/placeholder.png');
              }}
            />
          ) : (
            <Card
              className={`${classes.imgContainer} ${classes.imgContainerSmall}`}
              elevation={0}
            >
              <img
                alt=''
                className={classes.img}
                src={toAbsoluteUrl('/images/placeholder.png')}
              />
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default ImageGallery;
