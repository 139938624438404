import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getMe } from '../../../../../crud/profile.crud';
import { actions as authActions } from '../../../../../store/ducks/auth.duck';

export const useGetMe = () => {
  const dispatch = useDispatch();

  const fetchMe = useCallback(async () => {
    getMe()
      .then(res => {
        dispatch(authActions.loginSetUser(res.data.data));
      })
      .finally(() => {});
  }, []);

  return { fetchMe };
};
