import { LayoutFooter, LayoutSubheader } from '../../_metronic';
import { IBreadcrumb } from '../interfaces/breadcrumb';

interface IProps {
  title?: string;
  breadcrumb?: IBreadcrumb[] | undefined;
  description?: string;
  back?: boolean;
  show?: boolean;
}

export const setLayoutSubheader = ({
  title = '',
  breadcrumb = [],
  description = '',
  back,
  show = true,
}: IProps) => {
  LayoutSubheader({ title, breadcrumb, description, back, show });
};

export const setLayoutFooter = ({
  show = true
}: IProps) => {
  return (LayoutFooter({ show }))
}
