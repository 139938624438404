import React from 'react';
import LanguageSelector from '../../partials/layout/LanguageSelector';

export default class Topbar extends React.Component {
  render() {
    return (
      <div className='kt-language__topbar'>
        <LanguageSelector iconType='' />
      </div>
    );
  }
}
