import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { IconButton, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from 'react-router-dom';
import Rating from './Rating';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { API_DOMAIN } from '../../../../constants';
import Preloader from '../../../../components/ui/Preloader/Preloader';
import { useDefineUserRole } from '../../../../hooks';
import { IAppState } from '../../../../store/rootDuck';
import { useGetReviews } from '../hooks/useGetReviews';
import { useDeleteReview } from '../hooks/useDeleteReview';
import { Button } from '../../../../components/ui/Buttons';
import { IProduct } from '../../../../interfaces/product';
import { useStylesReviewsForm } from '../hooks/useStylesReviewForm';

interface IProps {
  meAdd?: boolean;
  product: IProduct;
  name: string;
}

const ReviewsForm: React.FC<IProps> = ({ meAdd = false, product, name }) => {
  const classes = useStylesReviewsForm();
  const intl = useIntl();
  const reviewsProduct = useSelector(
    ({ products: { reviewsProduct } }: IAppState) => reviewsProduct,
    shallowEqual
  );
  const me = useSelector(({ profile: { me } }: IAppState) => me, shallowEqual);
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');
  const history = useHistory();
  const productId = useMemo(() => product.id, [product]);

  const [fetchReviews, loading] = useGetReviews();
  const [deleteReview] = useDeleteReview();
  const [pageReviews, setPageReviews] = useState({ perPage: 20, page: 1 });

  useEffect(() => {
    productId && fetchReviews(productId, pageReviews.perPage, pageReviews.page);
  }, [productId, fetchReviews]);

  const handleDeleteReview = useCallback(
    (id: number) => {
      deleteReview(id, productId, pageReviews.perPage, pageReviews.page);
    },
    [productId, pageReviews]
  );

  if (loading) return <Preloader />;

  return (
    <>
      <div className={classes.headerTitle}>Отзывы</div>

      <div className={classes.reviews}>
        <div className={classes.reviewsList}>
          {reviewsProduct && reviewsProduct.reviews.length > 0 && (
            <>
              {reviewsProduct.reviews.map(item => (
                <div style={{ marginBottom: 32 }}>
                  <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
                    <div className={classes.avatar}>
                      <img
                        alt={item.user.fio}
                        src={
                          item.user.avatar
                            ? `${API_DOMAIN}/${item.user.avatar}`
                            : toAbsoluteUrl('/images/placeholder.png')
                        }
                      />
                    </div>

                    <div
                      style={{ display: 'flex', flexDirection: 'column', marginRight: 'auto' }}
                    >
                      <Typography style={{ marginBottom: 4 }}>
                        {item.user.fio} об этом продукте
                      </Typography>
                      <Rating rating={item.rating} />
                    </div>

                    {(isAdmin || item.user?.id === me?.id) && (
                      <Tooltip title={intl.formatMessage({ id: 'PRODUCT.REVIEW.BUTTON.DEL' })}>
                        <IconButton onClick={() => handleDeleteReview(item.id)}>
                          <DeleteIcon color='error' />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>

                  <Typography>{item.text}</Typography>
                </div>
              ))}

              <div
                style={{
                  paddingTop: 16,
                  borderTop: `1px solid red`,
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {reviewsProduct.total !== reviewsProduct.reviews.length && (
                  <Button style={{ cursor: 'pointer' }} color='primary' onClick={() => {}}>
                    Загрузать больше отзывов
                  </Button>
                )}
              </div>
            </>
          )}
        </div>

        <div className={classes.statistics}>
          <div className={classes.statisticsHeader}>
            <Rating rating={reviewsProduct?.rating} />

            <Typography style={{ fontSize: 17 }}>{reviewsProduct?.rating || 0}/5</Typography>
          </div>

          {reviewsProduct && (
            <div className={classes.statisticsList}>
              {Array(5)
                .fill('')
                .map((_, index) => {
                  const length =
                    reviewsProduct.reviews.filter(item => item.rating === 5 - index).length ||
                    0;
                  const percent = (length * 100) / reviewsProduct.reviews.length || 0;

                  return (
                    <div key={index} className={classes.statisticsItem}>
                      <Typography>{5 - index} зв.</Typography>
                      <div className='progress'>
                        <span style={{ width: `${percent}%` }} />
                      </div>
                      <Typography>{length}</Typography>
                    </div>
                  );
                })}
            </div>
          )}

          {(isBuyer || isAdmin) && !meAdd && (
            <div className={classes.feedButton}>
              <Button onClick={() => history.push('/product/review', { product, name })}>
                Оставить отзыв
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ReviewsForm;
