import { isArray } from 'lodash';
import { IStore } from '../../../interfaces/store';
import { IUser } from '../../../interfaces/user';

export const isJson = (str: any) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const setStoreStorage = (store: IStore[]) => {
  localStorage.setItem('storeShift', JSON.stringify(store));
};

export const getStoreStorage = (): IStore[] => {
  const storeShift = localStorage.getItem('storeShift');
  if (storeShift && storeShift !== 'undefined') {
    const parseData = JSON.parse(storeShift);
    // eslint-disable-next-line no-nested-ternary
    return !isArray(parseData) ? [parseData] : isArray(parseData) ? parseData : [];
  }
  return [];
};

export const removeStoreStorage = () => {
  localStorage.removeItem('storeShift');
};

export const setAllStorage = () => {
  localStorage.setItem('allObjects', 'true');
};

export const getAllStorage = () => {
  return localStorage.getItem('allObjects');
};

export const removeAllStorage = () => {
  localStorage.removeItem('allObjects');
};

export const setStatusStorage = (status: string[]) => {
  localStorage.setItem('statusShift', JSON.stringify(status));
};

export const getStatusStorage = () => {
  const storeShift = localStorage.getItem('statusShift');
  if (storeShift && storeShift !== 'undefined') {
    const parseData = isJson(storeShift) ? JSON.parse(storeShift) : storeShift;
    // eslint-disable-next-line no-nested-ternary
    return !isArray(parseData) ? [parseData] : isArray(parseData) ? parseData : [];
  }
  return [];
};

export const removeStatusStorage = () => {
  localStorage.removeItem('statusShift');
};

export const setCancelStorage = (status: boolean) => {
  localStorage.setItem('cancelShift', JSON.stringify(status));
};

export const getCancelStorage = () => {
  const storeShift = localStorage.getItem('cancelShift');
  return storeShift ? JSON.parse(storeShift) : null;
};

export const removeCancelStorage = () => {
  localStorage.removeItem('cancelShift');
};

export const setManagerStorage = (user: IUser[]) => {
  localStorage.setItem('managerShift', JSON.stringify(user));
};

export const getManagerStorage = () => {
  const managerShift = localStorage.getItem('managerShift');
  if (managerShift && managerShift !== 'undefined') {
    const parseData = JSON.parse(managerShift);
    // eslint-disable-next-line no-nested-ternary
    return !isArray(parseData) ? [parseData] : isArray(parseData) ? parseData : [];
  }
  return [];
};

export const removeManagerStorage = () => {
  localStorage.removeItem('managerShift');
};

export const setMonthStorage = (status: string) => {
  localStorage.setItem('monthShift', status);
};

export const getMonthStorage = () => {
  return localStorage.getItem('monthShift');
};

export const removeMonthStorage = () => {
  localStorage.removeItem('monthShift');
};

export const setSortStorage = (status: string) => {
  localStorage.setItem('sortShift', status);
};

export const getSortStorage = () => {
  const sortShift = localStorage.getItem('sortShift');
  return sortShift;
};

export const removeSortStorage = () => {
  localStorage.removeItem('sortShift');
};

export const removeShiftObjects = () => {
  localStorage.removeItem('shiftObjects.selectedStore');
  localStorage.removeItem('shiftObjects.checkSearch');
};

export const removeUsersPage = () => {
  localStorage.removeItem('UsersPage.startDate');
  localStorage.removeItem('UsersPage.endDate');
  localStorage.removeItem('UsersPage.selectedCategories');
  localStorage.removeItem('UsersPage.allCategories');
  localStorage.removeItem('UsersPage.startDateTime');
  localStorage.removeItem('UsersPage.endDateTime');
  localStorage.removeItem('UsersPage.free');
  localStorage.removeItem('UsersPage.active');
  localStorage.removeItem('UsersPage.myStaff');
  localStorage.removeItem('UsersPage.checkSearch');
  localStorage.removeItem('UsersPage.fio');
  localStorage.removeItem('UsersPage.phone');
  localStorage.removeItem('UsersPage.userPhone');
  localStorage.removeItem('UsersPage.checkId');
  localStorage.removeItem('UsersPage.company');
  localStorage.removeItem('UsersPage.manager');
};

export const removeUserEdit = () => {
  localStorage.removeItem('UserEdit.showVacations');
  localStorage.removeItem('UserEdit.status');
  localStorage.removeItem('UserEdit.showCanceled');
  localStorage.removeItem('UserEdit.showCompany');
};

export const removeStatUsers = () => {
  localStorage.removeItem('StatUsers.month');
  localStorage.removeItem('StatUsers.visibleScheduledHours');
  localStorage.removeItem('StatUsers.companies');
  localStorage.removeItem('StatUsers.managers');
};

export const removeStatObject = () => {
  localStorage.removeItem('StatObject.startString');
  localStorage.removeItem('StatObject.endString');
  localStorage.removeItem('StatObject.selectedStatuses');
  localStorage.removeItem('StatObject.selectedCompanies');
  localStorage.removeItem('StatObject.selectedCompaniesBuyer');
  localStorage.removeItem('StatObject.selectedManager');
  localStorage.removeItem('StatObject.storeManagers');
  localStorage.removeItem('StatObject.buyerManagers');
};
