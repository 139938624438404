import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { put, takeLatest, call } from 'redux-saga/effects';

import { TAppActions } from '../rootDuck';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import {
  getUsersByRole,
  createUser,
  editUser,
  getUser,
  sendInvite,
  delUser,
  uploadUserAvatar,
  getUsersListByRole,
} from '../../crud/users.crud';
import { IUser, IUserAddProps, IUserEditProps, TUserAttr } from '../../interfaces/user';
import { getResponseMessage } from '../../utils/utils';
import { TUserFilter } from '../../components/ui/UsersFilter/types';

const CLEAR_FETCH = 'users/CLEAR_FETCH';
const FETCH_REQUEST = 'users/FETCH_REQUEST';
const FETCH_SUCCESS = 'users/FETCH_SUCCESS';
const FETCH_FAIL = 'users/FETCH_FAIL';

const CLEAR_FETCH_BY_ID = 'users/CLEAR_FETCH_BY_ID';
const FETCH_BY_ID_REQUEST = 'users/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'users/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'users/FETCH_BY_ID_FAIL';

const CLEAR_EDIT = 'users/CLEAR_EDIT';
const ADD_REQUEST = 'users/ADD_REQUEST';
const EDIT_REQUEST = 'users/EDIT_REQUEST';
const EDIT_SUCCESS = 'users/EDIT_SUCCESS';
const EDIT_FAIL = 'users/EDIT_FAIL';

const CLEAR_SEND_INVITE = 'users/CLEAR_SEND_INVITE';
const SEND_INVITE_REQUEST = 'users/SEND_INVITE_REQUEST';
const SEND_INVITE_SUCCESS = 'users/SEND_INVITE_SUCCESS';
const SEND_INVITE_FAIL = 'users/SEND_INVITE_FAIL';

const CLEAR_DEL_USER = 'users/CLEAR_DEL_USER';
const DEL_USER_REQUEST = 'users/DEL_USER_REQUEST';
const DEL_USER_SUCCESS = 'users/DEL_USER_SUCCESS';
const DEL_USER_FAIL = 'users/DEL_USER_FAIL';

const SET_USERATTR = 'users/SET_USERATTR';

const SET_FILTER = 'users/SET_FILTER';

const UPLOAD_AVATAR_REQUEST = 'users/UPLOAD_AVATAR_REQUEST';
const UPLOAD_AVATAR_SUCCESS = 'users/UPLOAD_AVATAR_SUCCESS';
const UPLOAD_AVATAR_FAIL = 'users/UPLOAD_AVATAR_FAIL';
const CLEAR_PAGINATION = 'users/CLEAR_PAGINATION';
const ADD_SUCCESS = 'users/ADD_SUCCESS';
const CLEAR_ADD_USER_ID = 'users/CLEAR_ADD_USER_ID';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  users: IUser[];
  loading: boolean;
  success: boolean;
  error: string | null;

  user: IUser | null;
  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  sendInviteLoading: boolean;
  sendInviteSuccess: boolean;
  sendInviteError: string | null;

  delUserLoading: boolean;
  delUserSuccess: boolean;
  delUserError: string | null;

  userAttr: null | TUserAttr;

  filter: { [key: string]: string[] };

  uploadAvatarLoading: boolean;
  uploadAvatarSuccess: boolean;
  uploadAvatarError: string | null;
  addSuccess: boolean;
  addUserId: number | undefined;
}

const initialState: IInitialState = {
  page: 1,
  per_page: 20,
  total: 0,
  users: [],
  loading: false,
  success: false,
  error: null,

  user: null,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,

  editLoading: false,
  editSuccess: false,
  editError: null,

  sendInviteLoading: false,
  sendInviteSuccess: false,
  sendInviteError: null,

  delUserLoading: false,
  delUserSuccess: false,
  delUserError: null,

  userAttr: null,

  filter: {},

  uploadAvatarLoading: false,
  uploadAvatarSuccess: false,
  uploadAvatarError: null,
  addSuccess: false,
  addUserId: undefined,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'users', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_FETCH: {
        return { ...state, loading: false, error: null, success: false };
      }

      case FETCH_REQUEST: {
        return {
          ...state,
          users: [],
          loading: true,
          error: null,
          success: false,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          users: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case CLEAR_PAGINATION: {
        return {
          ...state,
          page: 1,
          per_page: 20,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case CLEAR_FETCH_BY_ID: {
        return {
          ...state,
          user: null,
          byIdLoading: false,
          byIdError: null,
          byIdSuccess: false,
        };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          user: null,
          byIdLoading: true,
          byIdError: null,
          byIdSuccess: false,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return { ...state, user: action.payload.data, byIdLoading: false, byIdSuccess: true };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, byIdLoading: false, byIdError: action.payload };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          editLoading: false,
          editError: null,
          editSuccess: false,
          user: null,
        };
      }

      case ADD_REQUEST: {
        return { ...state, editLoading: true, editError: null, editSuccess: false };
      }

      case EDIT_REQUEST: {
        return {
          ...state,
          editLoading: true,
          editError: null,
          editSuccess: false,
          addSuccess: false,
          addUserId: undefined,
        };
      }

      case ADD_SUCCESS: {
        return {
          ...state,
          editLoading: false,
          // editSuccess: true,
          addUserId: action.payload.id,
          addSuccess: true,
          product: undefined,
        };
      }

      case CLEAR_ADD_USER_ID: {
        return {
          ...state,
          addSuccess: false,
          addUserId: undefined,
        };
      }

      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true, user: action.payload };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case CLEAR_SEND_INVITE: {
        return {
          ...state,
          sendInviteLoading: false,
          sendInviteError: null,
          sendInviteSuccess: false,
        };
      }

      case SEND_INVITE_REQUEST: {
        return {
          ...state,
          sendInviteLoading: true,
          sendInviteError: null,
          sendInviteSuccess: false,
        };
      }

      case SEND_INVITE_SUCCESS: {
        return { ...state, sendInviteLoading: false, sendInviteSuccess: true };
      }

      case SEND_INVITE_FAIL: {
        return { ...state, sendInviteLoading: false, sendInviteError: action.payload };
      }

      case CLEAR_DEL_USER: {
        return { ...state, delUserLoading: false, delUserError: null, delUserSuccess: false };
      }

      case DEL_USER_REQUEST: {
        return { ...state, delUserLoading: true, delUserError: null, delUserSuccess: false };
      }

      case DEL_USER_SUCCESS: {
        return { ...state, delUserLoading: false, delUserSuccess: true };
      }

      case DEL_USER_FAIL: {
        return { ...state, delUserLoading: false, delUserError: action.payload };
      }

      case SET_USERATTR: {
        return { ...state, userAttr: action.payload };
      }

      case SET_FILTER: {
        return {
          ...state,
          filter: {
            ...state.filter,
            ...action.payload,
          },
        };
      }

      case UPLOAD_AVATAR_REQUEST: {
        return {
          ...state,
          uploadAvatarLoading: true,
          uploadAvatarError: null,
          uploadAvatarSuccess: false,
        };
      }

      case UPLOAD_AVATAR_SUCCESS: {
        return {
          ...state,
          user: action.payload.data,
          uploadAvatarLoading: false,
          uploadAvatarError: null,
          uploadAvatarSuccess: true,
        };
      }

      case UPLOAD_AVATAR_FAIL: {
        return {
          ...state,
          uploadAvatarLoading: false,
          uploadAvatarError: action.payload,
          uploadAvatarSuccess: false,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearFetch: () => createAction(CLEAR_FETCH),
  fetchRequest: (payload: {
    page: number;
    perPage: number;
    role: string;
    filter: TUserFilter;
    companyId?: number;
    isUserList?: boolean;
  }) => createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<IUser[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  clearFetchById: () => createAction(CLEAR_FETCH_BY_ID),
  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<IUser>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  addRequest: (payload: IUserAddProps) => createAction(ADD_REQUEST, payload),
  editRequest: (payload: { id: number; data: IUserEditProps }) =>
    createAction(EDIT_REQUEST, payload),
  editSuccess: (data: IUser) => createAction(EDIT_SUCCESS, data),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),

  clearSendInvite: () => createAction(CLEAR_SEND_INVITE),
  sendInviteRequest: (payload: string) => createAction(SEND_INVITE_REQUEST, payload),
  sendInviteSuccess: () => createAction(SEND_INVITE_SUCCESS),
  sendInviteFail: (payload: string) => createAction(SEND_INVITE_FAIL, payload),

  clearDelUser: () => createAction(CLEAR_DEL_USER),
  delUserRequest: (payload: number) => createAction(DEL_USER_REQUEST, payload),
  delUserSuccess: () => createAction(DEL_USER_SUCCESS),
  delUserFail: (payload: string) => createAction(DEL_USER_FAIL, payload),

  setUserAttr: (payload: TUserAttr) => createAction(SET_USERATTR, payload),

  setFilter: (payload: any) => createAction(SET_FILTER, payload),

  uploadAvatarRequest: (payload: { photo: FormData; id: string }) =>
    createAction(UPLOAD_AVATAR_REQUEST, payload),
  uploadAvatarSuccess: (payload: IServerResponse<IUser>) =>
    createAction(UPLOAD_AVATAR_SUCCESS, payload),
  uploadAvatarFail: (payload: string) => createAction(UPLOAD_AVATAR_FAIL, payload),
  clearPagination: () => createAction(CLEAR_PAGINATION),
  addSuccess: (payload: IUser) => createAction(ADD_SUCCESS, payload),
  clearAddUserId: () => createAction(CLEAR_ADD_USER_ID),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({
  payload,
}: {
  payload: {
    page: number;
    perPage: number;
    role: string;
    filter: TUserFilter;
    companyId?: number;
    isUserList?: boolean;
  };
}) {
  try {
    const { data }: { data: IServerResponse<IUser[]> } = yield call(() =>
      payload.companyId || payload.isUserList
        ? getUsersListByRole(
            payload.page,
            payload.perPage,
            payload.role,
            undefined,
            payload.companyId
          )
        : getUsersByRole(payload.page, payload.perPage, payload.role, payload.filter)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
  }
}

function* fetchByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() => getUser(payload));
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(getResponseMessage(e)));
  }
}

function* addSaga({ payload }: { payload: IUserAddProps }) {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() => createUser(payload));
    yield put(actions.addSuccess(data.data));
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

function* editSaga({ payload }: { payload: { id: number; data: IUserEditProps } }) {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() =>
      editUser(payload.id, payload.data)
    );
    yield put(actions.editSuccess(data.data));
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

function* sendInviteSaga({ payload }: { payload: string }) {
  try {
    yield call(() => sendInvite(payload));
    yield put(actions.sendInviteSuccess());
  } catch (e) {
    yield put(actions.sendInviteFail(getResponseMessage(e)));
  }
}

function* delUserSaga({ payload }: { payload: number }) {
  try {
    yield call(() => delUser(payload));
    yield put(actions.delUserSuccess());
  } catch (e) {
    yield put(actions.delUserFail(getResponseMessage(e)));
  }
}

function* uploadAvatarSaga({ payload }: { payload: { photo: FormData; id: string } }) {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() =>
      uploadUserAvatar(payload)
    );
    yield put(actions.uploadAvatarSuccess(data));
  } catch (e) {
    yield put(actions.uploadAvatarFail(e?.response?.data?.message || 'Network error'));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(
    FETCH_BY_ID_REQUEST,
    fetchByIdSaga
  );
  yield takeLatest<ReturnType<typeof actions.addRequest>>(ADD_REQUEST, addSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
  yield takeLatest<ReturnType<typeof actions.sendInviteRequest>>(
    SEND_INVITE_REQUEST,
    sendInviteSaga
  );
  yield takeLatest<ReturnType<typeof actions.delUserRequest>>(DEL_USER_REQUEST, delUserSaga);
  yield takeLatest<ReturnType<typeof actions.uploadAvatarRequest>>(
    UPLOAD_AVATAR_REQUEST,
    uploadAvatarSaga
  );
}
